@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Red Hat Mono", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

code.inline {
  position: relative;
  top: -0.1rem;
  display: inline;
  padding: 0.05rem 0.5rem;
  background: rgb(50, 49, 48);
  border-radius: 0.25rem;
  font-size: 0.85rem;
  color: #fff !important;
}

.space-y-1 > * + * {
  margin-top: 0.5rem !important;
}

.ms-TextField > *:only-child {
  margin-bottom: 2.1rem;
}

.ms-ChoiceField-field::before {
  top: 0.2rem;
}
.ms-ChoiceField-field::after {
  top: 0.5rem;
}

.ms-ChoiceField-wrapper > *:hover::after {
  top: 0.5rem;
}

.ms-ChoiceFieldGroup > * {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  white-space: pre-wrap;
}

ul.no-bullets {
  list-style-type: none; /* Remove bullets */
}
